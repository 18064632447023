import { memo } from "react"
import { Box, HStack, Container, Heading, Button, Link, Tag, Text, VStack, Icon, Divider, Stack } from "@chakra-ui/react"
import { FaInstagram } from "react-icons/fa"
import { NutritionistLogoPortrait } from "./NutritionistLogoPortrait"
import { SocialLinks } from "@components/SocialMedia"
import { CustomLink } from "../Link"

type NutritionistHeroProps = {
  nutritionist?: any
}

const NutritionistHero: React.FC<NutritionistHeroProps> = ({ nutritionist }) => {
  const hasTags = !!nutritionist?.tags?.length
  const instagram = nutritionist?.instagram?.link

  return (
    <Box bg="brand.lavender" pt={[16, 16, 32]} pb={[14, 14, 32]}>
      <Container>
        <Stack
          direction={["column", "column", "row"]}
          alignItems={["center", "center", "unset"]}
          spacing={[4, 4, 10, 12]}
          justifyContent={["unset", "unset", "center"]}
        >
          <Box w={["full", "full", "259px", "520px"]} alignSelf="center">
            <NutritionistLogoPortrait image={nutritionist?.image} logo={nutritionist?.logo} />
          </Box>

          <Box maxW="550px" alignSelf="center">
            <VStack spacing={[5, 5, 6]}>
              <Heading as="h1" size="h1" textAlign="center">
                {nutritionist?.title}
              </Heading>
              {hasTags && (
                <HStack spacing={2} justifyContent="center">
                  {nutritionist?.tags?.map((tag: any) => (
                    <Tag key={tag?._key} bgColor={tag?.bgColor} variant="nutritionist-hero">
                      {tag?.title}
                    </Tag>
                  ))}
                </HStack>
              )}
              {nutritionist?.jobTitle && (
                <Text textAlign="center" fontSize={["xl", "xl", "2xl"]}>
                  {nutritionist?.jobTitle}
                </Text>
              )}
              <Stack w="full" spacing={[3, 3, 4]} justifyContent="center" direction={["column", "column", "row"]}>
                <Link href="#contact" w={["full", "full", "half"]}>
                  {/* TODO: add this to locales */}
                  <Button w="full" fontSize="xl" size="lg">
                    Book 1:1
                  </Button>
                </Link>
                {instagram && (
                  <CustomLink to={instagram} styles={{ w: ["full", "full", "half"] }}>
                    <Button w="full" variant="outline" fontSize="xl" size="lg">
                      <Icon as={FaInstagram} mr={2.5} />
                      {/* TODO: add this to locales */}
                      Follow
                    </Button>
                  </CustomLink>
                )}
              </Stack>
              <HStack spacing={4.5}>
                {/* TODO: add to locales */}
                <Text fontSize="md">Share:</Text>
                <SocialLinks />
              </HStack>
              <Divider borderBottomWidth="1.5px" borderColor="brand.avocado" />
              {/* TODO: reviews and wishlists once setup */}
            </VStack>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

const MemoNutritionistHero = memo(NutritionistHero)
export { MemoNutritionistHero as NutritionistHero }
