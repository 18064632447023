import { graphql, PageProps } from "gatsby"
import Template from "@components/Nutritionist/Nutritionist"

export type Props = PageProps<GatsbyTypes.TemplateNutritionistQuery, GatsbyTypes.TemplateNutritionistQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query TemplateNutritionist($id: String!) {
    nutritionist: sanityNutritionist(_id: { eq: $id }) {
      ...SanityNutritionistFragment
    }
  }
  fragment SanityNutritionistFragment on SanityNutritionist {
    title
    _id
    handle {
      current
    }
    summary
    sections: _rawSections(resolveReferences: { maxDepth: 8 })
    jobTitle
    tags: _rawTags(resolveReferences: { maxDepth: 1 })
    image: _rawImage(resolveReferences: { maxDepth: 1 })
    logo: _rawLogo(resolveReferences: { maxDepth: 1 })
    instagram: _rawInstagram(resolveReferences: { maxDepth: 1 })
    _type
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
