import { Box } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"
import { memo } from "react"

type NutritionistLogoPortraitProps = {
  image: any
  logo: any
}

const NutritionistLogoPortrait: React.FC<NutritionistLogoPortraitProps> = ({ image, logo }) => {
  return (
    <Box w={["227px", "227px", "227px", "437px"]} position="relative" mx={["auto", "auto", "unset"]}>
      <CustomImage
        src={image?.asset?.url}
        alt={image?.alt}
        h={["311px", "311px", "311px", "600px"]}
        w="full"
        objectFit="cover"
        borderRadius={["110px", "110px", "110px", "230px"]}
      />
      {logo?.asset && (
        <Box
          bg="brand.offWhite"
          position="absolute"
          bottom={[8, 8, 8, 16]}
          right={[-8, -8, -8, -20]}
          borderRadius="round"
          w={[27, 27, 27, 48]}
          h={[27, 27, 27, 48]}
          display="flex"
          border="1.5px"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
        >
          <CustomImage src={logo?.asset?.url} alt={logo?.alt} w="80%" />
        </Box>
      )}
    </Box>
  )
}

const MemoNutritionistLogoPortrait = memo(NutritionistLogoPortrait)
export { MemoNutritionistLogoPortrait as NutritionistLogoPortrait }
