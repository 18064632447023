import Sections from "@components/Sections/Sections"
import { NutritionistHero } from "./NutritionistHero"
import { memo } from "react"

import type { Props } from "@app/templates/nutritionist"
import type { PageProps } from "@root/types/global"

const Nutritionist: React.FC<PageProps<Props>> = ({ nutritionist, location }) => {
  const hasSections = !!nutritionist?.sections?.length

  return (
    <>
      <NutritionistHero nutritionist={nutritionist} />
      {hasSections && <Sections page={{ content: nutritionist?.sections }} location={location} nutritionistName={nutritionist?.title} />}
    </>
  )
}

export default memo(Nutritionist)
